<template>
  <div>
    <!-- Início filtro -->
    <v-dialog v-model="modal.filtro" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2">
            fas fa-shipping-fast
          </v-icon>
          <v-toolbar-title class="pl-4">
            Filtrar transportadoras
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon @click="carregarTransportadoras(), modal.filtro = false">
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field dense outlined prepend-icon="fas fa-fingerprint" label="Código" type="number"
                    autocomplete="off" v-model="filtros.codigo" clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field dense outlined prepend-icon="fas fa-dolly" label="Nome" type="text"
                    autocomplete="off" v-model="filtros.transportadora" clearable></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field dense clearable outlined prepend-icon="fas fa-globe" label="URL" type="text"
                    autocomplete="off" v-model="filtros.url">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- Inicio Add -->
    <v-dialog v-model="modal.add" max-width="900">
      <v-card>
        <v-card-item style="display: flex; justify-content: center;"><v-spacer></v-spacer>
          <v-icon style="padding-left: 50px;">fas fa-shipping-fast</v-icon>
          <v-card-title>Adicionar Transportadora</v-card-title><v-spacer></v-spacer>
        </v-card-item>
        <v-form>
          <v-container>
            <v-row class="rowStyle">
              <v-col cols="10">
                <v-text-field
                  label="Nome"
                  outlined
                  :rules="[() => !!newTransport.nome || 'Campo obrigatório']"
                  v-model="newTransport.nome">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="rowStyle">
              <v-col cols="10">
                <v-text-field
                  label="Site"
                  outlined
                  :rules="[() => !!newTransport.site || 'Campo obrigatório']"
                  v-model="newTransport.site">
                </v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row style="justify-content: center; position: relative; right: 30px;"> -->

            <v-row class="rowStyle">
              <v-col cols="10">
                <v-file-input
                    accept="image/png, image/jpeg, image/bmp, image/svg"
                    v-model="newTransport.image"
                    label="Imagem"
                    outlined
                >
                </v-file-input>
              </v-col>
            </v-row>

            <!-- <v-card-item style="justify-content: end; display: flex;">
              <v-btn color="error" @click="modal.add = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" class="me-4" style="margin-left: 10px" :loading="loading" @click="addTransp()">
                Adicionar
              </v-btn>
            </v-card-item> -->
          </v-container>

          <v-card-actions class="alignmentDialog">
            <v-btn color="error" @click="modal.add = false">
              Cancelar
            </v-btn>
            <v-btn color="primary" class="me-4" style="margin-left: 10px" :loading="loading" @click="addTransp()">
              Adicionar
            </v-btn>
          </v-card-actions>

        </v-form>
      </v-card>
    </v-dialog>
    <!-- Final Add -->

    <!-- Inicio Edit Item -->
    <v-dialog v-model="modal.edit" max-width="900">
      <v-card>
        <v-card-item style="display: flex; justify-content: center;">
          <v-spacer></v-spacer>
          <v-card-title class="text-h5" style="
                    position: relative;
                    left: 20px;">Editar Transportadora</v-card-title>
          <v-spacer></v-spacer>
        </v-card-item>

        <v-card-item>
          <v-form>
            <v-container>
              <v-row class="rowStyle">
                <v-col cols="2">
                  <v-text-field
                    v-model="this.editedItem.codigo"
                    label="Código"
                    outlined
                    disabled>
                  </v-text-field>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Nome"
                    outlined
                    :rules="[() => !!editedItem.transportadora > 0 || 'Campo obrigatório']"
                    v-model="editedItem.transportadora"
                    >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="rowStyle">
              <v-col cols="11">
                <v-text-field
                  label="Site"
                  outlined
                  :rules="[() => !!editedItem.site || 'Campo obrigatório']"
                  v-model="editedItem.site">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="rowStyle">
              <v-col cols="11">
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, image/svg"
                  v-model="editedItem.imagem"
                  :disabled="!!editedItem.imagem ? true : false"
                  label="Imagem"
                  outlined
                >
                </v-file-input>
              </v-col>
            </v-row>

              <!-- <v-card-item style="justify-content: end; display: flex;">
                <v-btn color="error" @click="modal.edit = false">
                  Cancelar
                </v-btn>
                <v-btn color="primary" class="me-4" style="margin-left: 10px" :loading="loading"
                  @click="editTransp()"
                >
                  Confirmar
                </v-btn>
              </v-card-item> -->
            </v-container>

            <v-card-actions class="alignmentDialog">
              <v-btn color="error" @click="modal.edit = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" class="me-4" style="margin-left: 10px" :loading="loading"
                @click="editTransp()"
              >
                Confirmar
              </v-btn>
            </v-card-actions>

          </v-form>
        </v-card-item>
      </v-card>
    </v-dialog>
    <!-- Final Edit Item -->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon>
          fas fa-shipping-fast
        </v-icon>
        <v-toolbar-title class="pl-4">
          Transportadoras
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn style="background-color: #018656;" text @click="(modal.add = true, clearNewTransport())">
          <span style="color: white;">Adicionar</span>
        </v-btn>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.transportadoras.cabecalho"
              :items="grid.transportadoras.items"
              :items-per-page="grid.transportadoras.porPagina"
              hide-default-footer class="elevation-1 pt-4"
              :loading="grid.transportadoras.carregando">
              <template v-slot:item.actions="{ item }">
                <div>
                  <v-icon style="margin-left: 8px;" color="primary" @click="editItem(item)" size="small" class="me-2">fas fa-pen</v-icon>
                  <!-- <v-icon color="error" @click="modal.delete = true" size="small">fas fa-trash</v-icon> -->
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="2">
            <v-btn fab small color="primary" dark @click="voltarTopo()" style="margin-left: 10px;">
              <v-icon> fas fa-arrow-up </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-pagination v-model="grid.transportadoras.paginaAtual"
              :length="Math.ceil(grid.transportadoras.totalRegistros / this.grid.transportadoras.porPagina)"
              @input="mudarPagina" :total-visible="5"></v-pagination>
          </v-col>
          <v-col cols="2">
            <v-select
              dense
              outlined
              label="Resultados por página"
              :items="grid.transportadoras.qtdsPaginacao"
              item-text="text"
              item-value="value"
              v-model="grid.transportadoras.porPagina"></v-select>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'

export default {
  name: 'Transportadoras',
  data: () => ({
    grid: {
      transportadoras: {
        cabecalho: [
          { text: 'Código', value: 'codigo' },
          { text: 'Transportadora', value: 'transportadora'},
          { text: 'Site', value: 'site', sortable: false },
          { text: 'Imagem', value: 'imagem', sortable: false },
          { text: 'Editar', value: 'actions', sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 50,
      },
    },
    modal: {
      filtro: false,
      add: false,
      edit: false,
      delete: false,
    },
    filtros: {
      codigo: '',
      transportadora: '',
      url: '',
    },
    newTransport: {
      nome: "",
      site: "",
      image: "",
    },
    editedIndex: -1,
    editedItem: {
      codigo: '',
      transportadora: '',
      url: '',
      imagem: '',
    },
    loading: false,
  }),
  watch: {
    loading(val) {
      if (!val) return

      setTimeout(() => (this.loading = false, this.modal.add = false), 2000)
    },
  },
  mounted() {
    this.carregarTransportadoras()
  },
  methods: {
    clearNewTransport(){
      this.newTransport.nome = "";
      this.newTransport.site = "";
      this.newTransport.image = "";
    },
    limparFiltros() {
      this.filtros.codigo = ''
      this.filtros.transportadora = ''
      this.filtros.url = ''
    },
    fechar() {
      this.$router.push('/')
    },
    carregarTransportadoras() {
      this.grid.transportadoras.carregando = true
      let url = `${baseApiUrl}/transportadoras`
      if (this.filtros.codigo != '' || this.filtros.transportadora != '')
        url += `?codigo=${this.filtros.codigo}&transportadora=${this.filtros.transportadora}`
      axios.get(url)
        .then(res => {
          this.grid.transportadoras.items = res.data.transportadoras
        })
        .finally(() => this.grid.transportadoras.carregando = false)
    },
    voltarTopo() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    editItem(item) {
      this.editedItem.codigo = item.codigo;
      this.editedItem.transportadora = item.transportadora;
      this.editedItem.site = item.site;
      this.editedItem.imagem = !!item.imagem;
      this.modal.edit = true;
      // this.editedItem = Object.assign({}, item);
    },
    addTransp() {
      let file = this.newTransport.image;
      let config = { header : { 'Content-Type' : 'multipart/form-data' } }
      let data = new FormData()
      data.append('image', file, file.name)
      data.append('transportadora', this.newTransport.nome )
      data.append('site', this.newTransport.site )
      let url = `${baseApiUrl}/transportadoras`

      this.$swal({
        title: 'Confirmação',
        html: `Adicionar a Transportadora ${ this.newTransport.nome }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios.post(url, data, config)
          .then((res) => {
              this.$swal({
              title: 'Sucesso!!!',
              text: 'Transportadora incluida com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
              this.carregarTransportadoras();
          });
        }
      });
      this.modal.add = false;
    },
    editTransp() {
      this.$swal({
        title: 'Alerta',
        html: `Salvar as alterações ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let file = this.editedItem.imagem;
          let config = { header : { 'Content-Type' : 'multipart/form-data' } }
          let data = new FormData()
          if (file.name) {
            data.append('image', file, file.name)
          }
          data.append('transportadora', this.editedItem.transportadora )
          data.append('site', this.editedItem.site )

          await axios
          .put(`${baseApiUrl}/transportadoras/${this.editedItem.codigo}`, data, config)
          .then((response) => {
            if (response.data.retorno) {
              this.$swal({
                title: 'Sucesso!!!',
                text: 'Informações Alteradas com sucesso.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            } else {
              this.$swal({
                title: 'Que pena!!!',
                text: `${response.data.mensagem}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            }
          })
          .catch((error) => {
            this.$swal({
              title: 'Que pena!!!',
              text: 'Não foi possível concluir!!!',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
          .finally(() => {
            this.modal.edit = false;
          });
        }
      });
      this.modal.edit = false;
      this.carregarTransportadoras();
    },

  }
}
</script>

<style scoped>
  .rowStyle {
      height: 80px;
      justify-content: center;
  }
</style>
